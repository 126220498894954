<template>
    <div class = "homePage">
        <div class = "main-section">
            <div class = "container">
        <animated-component animationType="right">
            <h1 class = "maintext">Catechize</h1>
            <!-- <h2 class = "subtext">We make your sermons more searchable</h2> -->
        </animated-component>

        <animated-component animationType="left">
            <h2 class = "subtext">We make your sermons more searchable</h2>
        </animated-component>
            <div class="buttons">
              <form action = "/home">
                <button id="searchSubmit" type="submit"> Search public sermons</button>
              </form>
                <p1 id="or"> or </p1>
              <form action ="/upload">
                <button id="uploadSubmit" type="submit"> Upload your sermons</button>
              </form>
            </div>
            <div class = "tWrapper"> 
                <img src="../../../images/pictureBar.png" />
            </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.homePage {
  background-color: #0c0f15;
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin:0;
  height:100vh;


  // Colorful circle thing
  background: radial-gradient(310px 310px at center top,(#f18a08,#ee6b06,#e34f11,#b8292f,#a41845,#5e1c5c,#340b7a,black));

  .maintext {
      font-size: 85px;
      font-family:Arial, Helvetica, sans-serif;
      font-weight: normal;
      color: white;
      text-transform: capitalize;
  }

  .subtext {
      font-size: 25px;
      font-family:Arial, Helvetica, sans-serif;
      font-weight: normal;
      color: white;
  }

  // Pushing texts into circle gradient
  .maintext , .subtext{
    position:relative; 
    bottom:200px;
    left: 10px;
  }

  .main-section {
      margin-top: 40px;
  }

  #searchSubmit {
      background: #e25111;
    //   margin-right: 90px;

  }

  #uploadSubmit {
      background: #5371ff;
  }

    // Fonts & Sizes of Buttons
  #searchSubmit, #uploadSubmit {
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      height: 35px;
      width: 300px;
      color: white;
      border-radius: 30px;
      border: none;
  }

    // Makes buttons side-to-side
  .buttons {
      display: inline-block;
      position: relative;
      bottom: 150px;
  }

  #or {
      background: transparent;
      border: none;
      overflow: hidden;
      color:white;
      font-size:25px;
      font-family: "Montserrat", sans-serif;
      margin: 0 20px 0 20px;
  }

  .tWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // margin-bottom: 40px;
    position:relative;
    bottom: 90px;
    min-width: 300px;

    img {
      height: 150px;
      width: 700px;
      margin-left: 10px;
    }

  }
    



    // Styling for Title
  .main-section{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .container {
      display: flex;
      width: 1800px;
      max-width: 90%;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;

    }
  }

}
</style>

<style scoped>
/* Fade animation */
.fade-enter-active,
.fade1-enter-active,
.fade2-enter-active,
.fade3-enter-active {
  transition: all 1000ms ease;
}

.fade-enter-from,
.fade1-enter-from,
.fade2-enter-from,
.fade3-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade1-enter-active {
  transition-delay: 400ms;
}

.fade2-enter-active {
  transition-delay: 800ms;
}

.fade3-enter-active {
  transition-delay: 800ms;
}
</style>
