<template lang="">
  <div>
    <center>
    <h1 class="mainText">Sorry, we can't find that page</h1>

    <form class="homeBForm" action="/">
      <button class="homeB" type="submit">Return Home</button>
    </form>
    </center>
  </div>

  <div class="footer">
    <Footer></Footer>
  </div>
</template>

<script setup>
  import Footer from '/src/pages/layout/Footer.vue';
</script>


<script>


export default {};
</script>

<style lang="scss">

.homeBForm {
    padding-bottom: 150px;
}

.homeB {
  background-color: #E25112;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.mainText {
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer {
  padding-left:35px;
}

</style>
