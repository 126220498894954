<template>
  <UploadSermon id="splash" />
</template>

<script>
import UploadSermon from './components/UploadSermon.vue';

export default {
  components: {
    UploadSermon,
  },
  data() {
    return {};
  },
  methods: {
    submitTags(tags) {
      let tagList = JSON.parse(JSON.stringify(tags));
      console.log(tagList);
    }
  }
};
</script>

<style lang="scss">
div {
  font-family: Andale Mono, monospace;
}
div.canvas {
  margin: 0 100px;
}
div.header {
  font-size: 30px;
  display: inline;
}
div.h5 {
  font-size: 20px;
}
button {
  font-family: Andale Mono, monospace;
}
button.submit {
  background-color: #E25112; /* Green */
  border-radius: 20px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  margin: 20px;
}
button.submit:hover {
  color: #E25112; /* Green */
  background-color: white;
  border-color: #E25112;
}
button.remove {
  background-color: transparent;
  color: grey;
  padding: 2px;
  text-align: center;
  display: inline-block;
  border-width: 0;
  font-size: 20px;
}
button.remove:hover {
  color: red;
}
button.add {
  width: 38px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 10px;
  padding: 5px;
  border-color: black;
  border-width: 2px;
  border-radius: 30px;
  border-style: solid;
  color: white;
  background-color: #E25112;
}
button.add:hover {
  color: #E25112; /* Green */
  background-color: white;
  border-color: #E25112;
}
input {
  font-family: Andale Mono, monospace;
}
input.add {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  margin: 5px;
  padding: 5px;
  border-color: black;
  border-width: 2px;
  border-radius: 30px;
  border-style: solid;
}
input.add::placeholder {
  font-style: italic;
}
</style>
