<template>
  <SplashArea id="splash" />

</template>
<script setup>
import SplashArea from './components/SplashArea.vue';

import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const isLoggedIn = computed(() => {
  return store.state.ethers.address;
});
</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 50px;
}
</style>
