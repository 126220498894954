// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import HomePage from '../pages/home/components/HomePage.vue';
import Home from '../pages/home/Home.vue';
import NotFound from '../pages/not-found/NotFound.vue';
import Results from '../pages/results/Results.vue';
import Upload from '../pages/upload-sermon/Upload.vue';

// Create routes.
const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  }, // Newly added HomePage router
  {
    path: '/home', //remember to put in action page
    name: 'Home',
    component: Home
  },
  {
    path: '/upload',
    name:'Upload',
    component: Upload
  },
  {
    path: '/results',
    name: 'Results',
    component: Results
  },
  { path: '/:pathMatch(.*)*', component: NotFound }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
