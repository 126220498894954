<template>
  <div class="splashArea">
    <div class="main-section">
      <div class="container">
        <animated-component animationType="left">
          <h1 class="maintext">Upload Your</h1>
          <h1 class = "maintext2">Sermon</h1>
        </animated-component>
        <div class="searchBar">
          <animated-component animationType="left">
            <Tags @btn-click="submitTags"/>
          </animated-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Imports.
import Tags from './components/Tags.vue';

export default ({
  components: {
    Tags,
  }
})
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.splashArea {
  background-color: #0c0f15;
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin:0;
  height:100vh;

  // Colorful circle thing
  background: radial-gradient(310px 310px at center top,(#f18a08,#ee6b06,#e34f11,#b8292f,#a41845,#5e1c5c,#340b7a,black));


  // Title basically
  .maintext {
      font-size: 45px;
      font-family:Andale Mono, monospace;
      font-weight: 10;
      color: white;
      text-transform: capitalize;
  }

  .maintext2 {
      font-size: 45px;
      font-family:Andale Mono, monospace;
      font-weight: 10;
      color: white;
      text-transform: lowercase; 
  }

  // Pushing texts into circle gradient
  
  .maintext , .maintext2{
    position:relative; 
    bottom:40px;
    left: 10px;
  }

  // Magnifying Glass Icon
  .magnify {
    font-size: 28px;
    color:white;
    background-color: transparent; // Makes button transparent
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  //All 3 following lines help put submit button in search bar
  #search{
    float: right;
    margin-right: 30px;
    margin-top: 35px;
  }

  #searchField{
    float: left;
  }

  #searchSubmit{
    margin-left: -40px;
    margin-top: 0px;
    position:relative;
    bottom:260px;
    left: 10px;
  }

  .main-section{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .container {
      display: flex;
      width: 1800px;
      max-width: 90%;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;

    }
  }
}

.searchBar {
  background-position: center;
  background-size: contain;
  padding: 100px 40px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  bottom: 120px;

// Actual Search bar
  .sbar {
    height: 50px;
    width: 800px;
    font-size: 20px;
    border-radius:30px;
    border-style: solid;
    border-width: 1px;
    border-color: grey;
    font-family: "Montserrat", sans-serif;
    background-color:transparent;
    color:white;
    margin: 0 70 1 1auto;
    display: inline-block;
    padding-left: 20px;

    position:relative; //Moves search bar up to title
    bottom:270px;
    left: 30px;
  }
}

@media (max-width: 600px) {
  .splashArea .container h2 {
    font-size: 18px;
  }

  .splashArea .container {
    margin-left: 118px;
  }
  .splashArea .container {
    font-size: 4vw;
  }

  .container {
    display: none;
  }

  .splashArea   .container {
    width: 73%;
  }
}

@media (max-width: 450px) {
  .splashArea   .container h2 {
    font-size: 20px;
    margin-left: -20px;
  }
  .splashArea   .container {
    margin-left: 154px;
  }

  .splashArea   .container {
    width: 99%;
  }
}

@media (max-width: 390px) {
  .splashArea   .container h2 {
    font-size: 18px;
    margin-left: -37px;
  }
  .splashArea   .container {
    margin-left: 128px;
  }
}
</style>

<style scoped>
/* Fade animation */
.fade-enter-active,
.fade1-enter-active,
.fade2-enter-active,
.fade3-enter-active {
  transition: all 1000ms ease;
}

.fade-enter-from,
.fade1-enter-from,
.fade2-enter-from,
.fade3-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade1-enter-active {
  transition-delay: 400ms;
}

.fade2-enter-active {
  transition-delay: 800ms;
}

.fade3-enter-active {
  transition-delay: 800ms;
}
</style>

<!--Search Bar Code-->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');



//Placeholder Text Color
::placeholder {
  color: white;
  opacity: 1;
  font-family: Andale Mono, monospace;
  text-align:center;
}

</style>
