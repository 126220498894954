<template>
    <div class="component">
        <animated-component>
        <div class="centerItem">
            <iframe :src=embedUrl allow="autoplay"></iframe>
        </div>
        </animated-component>
        <div class="tagGrid">
            <div class="tagTextLeft">{{tags[0]}}</div> 
            <div class="tagTextMid">{{tags[1]}}</div>
            <div class="tagTextRight">{{tags[2]}}</div>

            <div class="tagTextLeft">{{tags[3]}}</div> 
            <div class="tagTextMid">{{tags[4]}}</div>
            <div class="tagTextRight">{{tags[5]}}</div>
        </div>
        <div class="transcription">

            {{transcriptText}} 
        </div>
    </div>
</template>    
<script>

export default {
  props: ["transcriptText", "tags", "embedUrl"],
  name: "Result",
  data() {
  },
  computed: {

  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.tagTextLeft {
  background-color: #E25112;
  border-radius: 25px;
  display: grid;
  justify-content: center;
  grid-column: 1;
}

.tagTextMid {
  background-color: #E25112;
  border-radius: 25px;
  display: grid;
  justify-content: center;
  grid-column: 2;
}

.tagTextRight {
  background-color: #E25112;
  border-radius: 25px;
  display: grid;
  justify-content: center;
  grid-column: 3;
}

.tagGrid {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
    padding: 25px;
}

.centerItem {
    justify-content: center;
    display: flex
}

.transcription {
    outline-color: black;
    outline-style: solid;
    padding: 30px;
    color: black;
    background-color: white;
}

.component {
    padding: 25px;
}


</style>