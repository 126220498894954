<template>
  <vue-horizontal responsive scroll>
    <section >
      <Result id="result" :tags=tags :embedUrl=videoUrl :transcriptText=txt />
    </section>
    <section >
      <Result id="result" :tags=tags1 :embedUrl=videoUrl1 :transcriptText=txt1 />
    </section>
    <section >
      <Result id="result" :tags=tags2 :embedUrl=videoUrl2 :transcriptText=txt2 />
    </section>
    <section >
      <Result id="result" :tags=tags3 :embedUrl=videoUrl3 :transcriptText=txt3 />
    </section>
    <section >
      <Result id="result" :tags=tags4 :embedUrl=videoUrl4 :transcriptText=txt4 />
    </section>
    <section >
      <Result id="result" :tags=tags3 :embedUrl=videoUrl3 :transcriptText=txt3 />
    </section>
    <section >
      <Result id="result" :tags=tags4 :embedUrl=videoUrl1 :transcriptText=txt1 />
    </section>
    <section >
      <Result id="result" :tags=tags2 :embedUrl=videoUrl2 :transcriptText=txt2 />
    </section>
  </vue-horizontal>

<Footer></Footer>
</template>
<script setup>
import Result from '../results/components/Result.vue';
import SplashArea from '../home/components/SplashArea.vue';
import Footer from '/src/pages/layout/Footer.vue';
import VueHorizontal from "vue-horizontal";

// Sample variables below
const txt = `Okay. Good afternoon everyone. Thank you for joining us. 
I'm Dan Bates with the greater Hamilton Chamber of Commerce. We're excited today to bring you yet. 
Another episode of the conversation today. Our facilitator is Byron Skaggs, once again works with
successful business owners leaders and Executives facing New Opportunities or simply feeling stuck.
He is a certified professional business coach and an entrepreneurial minded individual with over
25 years of professional executive management experience spanning business education and nonprofit sectors,
Byron co-founded and operated us-based Corporation of facilitated a successful successful sale and transition 
to new operators while in Egypt, he initiated Consulting project resulting in his creating and launching 
an advancement office for prestigious private International School that serves clients including the US 
Embassy and other embassies USA ID United Nations, Coca-Cola International.`

const tags = ["Tag 1!", "Tag 2!", "Tag 3!", "Tag 4!", "Tag 5!", "Tag 6!"]
const videoUrl = `https://drive.google.com/file/d/13R16BbUtkFK56vAPrbaMMMRyQCgro6PV/preview`

const txt1 = `Yeah, hello everyone and welcome. I'm Dan Bates president CEO of the greater
 Hamilton Chamber of Commerce where our vibrant business Community is thriving among rapid 
 economic Redevelopment and expansion. Thank you for joining us today with us today. We will 
 have Byron Skaggs and Liv dewire. I'd like to do some introductions here by everyone who will
be our facilitator works with successful business owners leaders and Executives facing New Opportunities.
We're simply feeling stuck. He is a certified professional business coach and an entrepreneurial minded
individual with over 25 years professional and executive management experience spanning business education 
and nonprofit sectors, Byron co-founded and operated us space Corporation and facilitated a successful sale 
and transition to new operators. Well in Egypt, he initiated a Consulting project resulting in his creating
and launching and advancement office for prestigious private International School that serves clients including the US Embassy.`

const tags1 = ["Tag 1!", "Tag 2!", "Tag 3!", "Tag 4!", "Tag 5!", "Tag 6!"]
const videoUrl1 = `https://drive.google.com/file/d/1Gq5_YqonRZQKy2dLQ8f4P6i5BcnkAqZv/preview`

const txt2 = `There was a time where cell phones were actually used and viewed as a premium piece that 
you know, only those who were really important whether their company deemed it important enough for them
 to have a phone special if it was a bag phone that sat in the car that you know, you know, that was
  Wireless and that was man that was Prestige. I did it and now a phone is a commodity right? It's not so 
  much the phone. It's what you can what kind of access you can get with the phone on. The flip side is the inspiration.`

const tags2 = ["Tag 1!", "Tag 2!", "Tag 3!", "Tag 4!", "Tag 5!", "Tag 6!"]
const videoUrl2 = `https://drive.google.com/file/d/1nodgJMpFEnxcVhSNxSj_KrzhOTIbz-PS/preview`

const txt3 = `Good afternoon, and thanks for joining us. I'm Dan Bates. 
I'm president and CEO of the greater Hamilton Chamber of Commerce. And we are thrilled 
you're joining us today either in person or online today. We have a great great prepared 
for you and we will be facilitated by as usual Byron Skaggs from infinite pain. So thank you. 
Bye for joining us today. We're really excited to have Chef tato from Tano's Bistro with locations
 in Hamilton and Loveland and our book today is going to be hug your customers. And so the conversation
  is really not just about the book but that is the basis where a lot of the conversation will be coming 
  from so with that I turn it over to Byron and thank you and enjoy the conversation`

const tags3 = ["Tag 1!", "Tag 2!", "Tag 3!", "Tag 4!", "Tag 5!", "Tag 6!"]
const videoUrl3 = `https://drive.google.com/file/d/1G1jvNx-pBlfQmH1_8ghKoPdib0vDgYIF/preview`

const txt4 = `Still starting right? Good afternoon everyone. I'm Dan Bates from the greater Hamilton Chamber of Commerce.
 Thanks for joining us today with our new broadcast. We have a live audience here as well as many of you tuning in from 
 your laptops and other devices out beyond our building. So thanks for joining us today. We're very excited. We're going
  to be discussing the book tribal leadership our facilitator today back by popular demand is Byron Skaggs with infinite
   pain and Byron is an international consultant and master and transforming leaders or organizations and culture`

const tags4 = ["Tag 1!", "Tag 2!", "Tag 3!", "Tag 4!", "Tag 5!", "Tag 6!"]
const videoUrl4 = `https://drive.google.com/file/d/17wihBo9C5FQQUmw7TEaJFKQmxjRNWiV2/preview`


</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 50px;
}
.vue-horizontal {
    margin-right: 35px;
    margin-left: 35px;
}
</style>